import { createSlice } from "@reduxjs/toolkit";

export const modalSlice = createSlice({
  name: "modal",
  initialState: {
    isReadMoreModalOpen: false,
  },
  reducers: {
    setIsReadMoreModalOpen: (state, action) => {
      state.isReadMoreModalOpen = action.payload;
    },
  },
});
export const { setIsReadMoreModalOpen } = modalSlice.actions;
export default modalSlice.reducer;
