import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storageSession from "redux-persist/lib/storage";
import persistStore from "redux-persist/es/persistStore";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import candidateSlice from "./slice/candidateSlice";
import authentication from "./slice/authentication";
import modalSlice from "./slice/modalSlice";

const reducers = combineReducers({
  candidateSlice,
  modalSlice,
  auth: authentication,
});

const persistConfig = {
  key: "root",
  storage: storageSession,
  whitelist: ["auth"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

const persistor = persistStore(store);

export { store, persistor };
