import PropTypes from "prop-types";
import React, { Suspense } from "react";
import styles from "./MainLayout.module.scss";

const MainLayout = props => {
  const { variant, className } = props;

  return (
    <div
      className={`${styles.MainLayout} ${
        styles[`MainLayout__${variant}`]
      } ${className}`}
    >
      <main>
        <Suspense>
          <div className="container">
            <div className="content-wrapper w-screen">{props.children}</div>
          </div>
        </Suspense>
      </main>
    </div>
  );
};

MainLayout.defaultProps = {
  variant: "default",
  className: "",
};

MainLayout.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default MainLayout;
