import { useEffect, useState } from "react";
import AppRouter from "./router";
import Offline from "./pages/Offline";

function App() {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setIsOnline(window.navigator.onLine);
    };

    window.addEventListener("online", handleOnlineStatusChange);

    window.addEventListener("offline", handleOnlineStatusChange);

    return () => {
      window.removeEventListener("online", handleOnlineStatusChange);
      window.removeEventListener("offline", handleOnlineStatusChange);
    };
  }, []);
  return (
    <div className="App w-full">{isOnline ? <AppRouter /> : <Offline />}</div>
  );
}

export default App;
