// authentication.js (Slice)
import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: false,
    accessToken: null,
    jwtToken: null,
    user: null,
    role: null,
  },
  reducers: {
    login: (state, action) => {
      state.isAuthenticated = true;
      state.accessToken = action.payload.accessToken;
    },
    logout: state => {
      state.isAuthenticated = false;
      state.accessToken = null;
      state.jwtToken = null;
    },
    setJWT: (state, action) => {
      state.jwtToken = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
  },
});

export const { login, logout, setJWT, setUser, setRole } = authSlice.actions;

export default authSlice.reducer;
