const messages = {};

export const interviewDuration = [
  { key: "00:30", value: "30 Minutes" },
  { key: "00:45", value: "45 Minutes" },
  { key: "01:00", value: "1 Hour" },
  { key: "01:30", value: "1.5 Hour" },
  { key: "02:00", value: "2 Hours" },
  { key: "02:30", value: "2.5  Hours " },
  { key: "03:00", value: "3 Hours" },
  { key: "03:30", value: "3.5" },
  { key: "04:00", value: "4 Hours" },
];

export const ROLE_TALENT_ACQUISITION = "Talent Acquisition";
export const ROLE_INTERVIEW_PANEL = "Interview Panel";

export const statusClassMap = {
  "New Application":
    "bg-yellow-200 text-yellow-800 text-sm rounded-[10px] py-1 px-1",
  "On Hold": "bg-blue-400 text-blue-800 text-sm rounded-[10px] py-1 px-1",
  "Technical On Hold":
    "bg-blue-400 text-blue-800 text-sm rounded-[10px] py-1 px-1",
  "Department Head On Hold":
    "bg-blue-400 text-blue-800 text-sm rounded-[10px] py-1 px-1",
  "Management On Hold":
    "bg-blue-400 text-blue-800 text-sm rounded-[10px] py-1 px-1",
  Unprocessed: "bg-yellow-200 text-yellow-800 text-sm rounded-[10px] py-1 px-1",
  "For Screening":
    "bg-orange-200 text-orange-800 text-sm rounded-[10px] py-1 px-1",
  Shortlisted: "bg-green-200 text-green-800 text-sm rounded-[10px] py-1 px-1",
  Select: "bg-green-200 text-green-800 text-sm rounded-[10px] py-1 px-1",
  Offer: "bg-green-300 text-green-900 text-sm rounded-[10px] py-1 px-1",
  "Screening Reject":
    "bg-red-200 text-red-800 text-sm rounded-[10px] py-1 px-1",
  "Technical Reject":
    "bg-red-200 text-red-800 text-sm rounded-[10px] py-1 px-1",
  "Department Head Reject":
    "bg-red-200 text-red-800 text-sm rounded-[10px] py-1 px-1",
  "Test Reject": "bg-red-200 text-red-800 text-sm rounded-[10px] py-1 px-1",
  "Management  Reject":
    "bg-red-200 text-red-800 text-sm rounded-[10px] py-1 px-1",
  Reject: "bg-red-200 text-red-800 text-sm rounded-[10px] py-1 px-1",
  "Reserved for Future":
    "bg-purple-200 text-blue-800 text-sm rounded-[10px] py-1 px-1",
  "Reserved For Future":
    "bg-purple-200 text-blue-800 text-sm rounded-[10px] py-1 px-1",
  Reserve: "bg-blue-200 text-blue-800 text-sm rounded-[10px] py-1 px-1",
  Scheduled: "bg-purple-200 text-purple-800 text-sm rounded-[10px] py-1 px-1",
  "HR Discussion":
    "bg-emerald-200 text-emerald-800 text-sm rounded-[10px] py-1 px-1",
  "Technical Drop Out":
    "bg-red-400 text-red-800 text-sm rounded-[10px] py-1 px-1",
  "Department Head Drop Out":
    "bg-red-400 text-red-800 text-sm rounded-[10px] py-1 px-1",
  "Management Drop Out":
    "bg-red-400 text-red-800 text-sm rounded-[10px] py-1 px-1",
  "Test Dropout": "bg-red-400 text-red-800 text-sm rounded-[10px] py-1 px-1",
  "Management Dropout":
    "bg-pink-200 text-pink-800 text-sm rounded-[10px] py-1 px-1",
  "Technical Round":
    "bg-orange-200 text-orange-800 text-sm rounded-[10px] py-1 px-1",
  "Management Round":
    "bg-orange-200 text-orange-800 text-sm rounded-[10px] py-1 px-1",
  "Department Round":
    "bg-orange-200 text-orange-800 text-sm rounded-[10px] py-1 px-1",
  Test: "bg-orange-200 text-orange-800 text-sm rounded-[10px] py-1 px-1",
  "Test On Hold": "bg-blue-400 text-blue-800 text-sm rounded-[10px] py-1 px-1",
};

export default messages;
