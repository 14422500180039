import PropTypes from "prop-types";
import styles from "./Offline.module.scss";

const NotFound = () => {
  return (
    <div className={`${styles.NotFound}`}>
      <div className="bg-slate-100 h-screen flex flex-row gap-4 w-full justify-center items-center">
        Oops !! Looks Like you are offline. Check your connection
      </div>
    </div>
  );
};

NotFound.defaultProps = {
  className: "",
};

NotFound.propTypes = {
  className: PropTypes.string,
};

export default NotFound;
